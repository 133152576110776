import VueKeyCloak from '@dsb-norge/vue-keycloak-js';

import Main from './Main.vue';
import router from './router/main';
import { create, http, styles } from './shared';

const app = create(Main);
// eslint-disable-next-line no-underscore-dangle
styles.__inject__(document.querySelector('head'));

http.interceptors.request.use((c) => {
    const config = c;
    const { token } = app.config.globalProperties.$keycloak;
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    config.headers.Accept = 'application/json';

    return config;
});

http.interceptors.request.use((c: any) => {
    const config: any = c;
    if (config.method === 'get' && config.params) {
        window.Object.keys(config.params).forEach((param: any) => {
            if (typeof config.params[param] === 'boolean') {
                config.params[param] = config.params[param] ? 1 : 0;
            }
        });
    }

    if (config.method === 'get' && config.params && config.params.sortBy && config.params.orderBy) {
        config.params.sort = `${config.params.sortBy}:${config.params.orderBy}`;
        config.params.per_page = config.params.per_page || 30;
        delete config.params.sortBy;
        delete config.params.orderBy;
    }

    return config;
}, (err) => {
    Promise.reject(err);
});

app.use(router);

app.use(VueKeyCloak, {
    logout: {
        redirectUri: 'https://spoq.digital',
    },
    config: {
        url: process.env.VUE_APP_KEYCLOAK_BASE_URL,
        realm: process.env.VUE_APP_KEYCLOAK_REALM,
        clientId: process.env.VUE_APP_KEYCLOAK_CLIENT_ID,
    },
    init: {
        onLoad: 'login-required',
        checkLoginIframe: false,
    },
    onReady: (keycloak: any) => {
        app.mount('products-manager');

        keycloak.loadUserProfile()
            .then((userProfile: any) => {
                app.config.globalProperties.$store.dispatch('getUser', userProfile);
            });
    },
});
