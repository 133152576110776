

import { defineComponent, PropType } from 'vue';

export default defineComponent({
    props: {
        modelValue: {
            type: undefined as any | PropType<unknown> | PropType<Array<any>>,
            default: undefined,
        },
    },
    data() {
        return {
            isScrollable: true,
            newValue: this.modelValue ? this.modelValue : null,
            groups: [
                {
                    label: 'Signs',
                    icons: [
                        'cog',
                        'cogs',
                        'fire',
                        'bolt',
                        'water',
                        'fire',
                        'vial',
                        'video',
                        'volume-up',
                        'faucet',
                        'exclamation-circle',
                        'exclamation-triangle',
                        'radiation',
                        'tools',
                    ],
                },
                {
                    label: 'Houses',
                    icons: [
                        'home',
                        'building',
                        'house-damage',
                        'warehouse',
                        'store',
                        'school',
                        'industry',
                        'hospital',
                        'university',
                        'hotel',
                        'campground',
                        'city',
                        'broadcast-tower',
                    ],
                },
                {
                    label: 'Cars',
                    icons: [
                        'car-side',
                        'truck',
                        'trailer',
                        'caravan',
                        'car-battery',
                        'gas-pump',
                        'dolly-flatbed',
                        'dolly',
                        'truck-loading',
                    ],
                },
            ],
        };
    },
    mounted() {
        this.newValue = this.modelValue || null;
    },
    methods: {
        onSelect(obj: any): void {
            this.newValue = obj;
            this.$emit('update:modelValue', obj);
        },
    },
});

