import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, withModifiers as _withModifiers, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_o_icon = _resolveComponent("o-icon")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(), _createBlock(_resolveDynamicComponent(!_ctx.multiple ? 'o-autocomplete' : 'o-inputitems'), {
      ref: "tag",
      modelValue: _ctx.newModelValue,
      "onUpdate:modelValue": [
        _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newModelValue) = $event)),
        _ctx.onInput
      ],
      autocomplete: _ctx.multiple ? 'autocomplete' : null,
      "check-infinite-scroll": true,
      clearable: true,
      closeIcon: '',
      createItem: _ctx.createItem,
      data: _ctx.data,
      field: 'name',
      icon: 'box',
      "icon-right": 'caret-down',
      loading: _ctx.loading,
      "open-on-focus": true,
      "append-to-body": "",
      placeholder: "Tag",
      onSelect: _ctx.onSelect,
      onTyping: _ctx.onTyping,
      onInfiniteScroll: _ctx.fetchMoreData,
      onFocus: _withModifiers(_ctx.onFocus, ["self"])
    }, {
      default: _withCtx((props) => [
        (props.option.icon)
          ? (_openBlock(), _createBlock(_component_o_icon, {
              key: 0,
              icon: props.option.icon
            }, null, 8, ["icon"]))
          : _createCommentVNode("", true),
        _createTextVNode(" " + _toDisplayString(props.option.name), 1)
      ]),
      empty: _withCtx(() => [
        (_ctx.loading)
          ? (_openBlock(), _createElementBlock("p", _hoisted_1, " Loading... "))
          : (_openBlock(), _createElementBlock("p", _hoisted_2, " No matching results "))
      ]),
      _: 1
    }, 40, ["modelValue", "autocomplete", "createItem", "data", "loading", "onSelect", "onTyping", "onInfiniteScroll", "onUpdate:modelValue", "onFocus"]))
  ]))
}