import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, normalizeClass as _normalizeClass, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = { class: "columns is-fullwidth" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_o_icon = _resolveComponent("o-icon")!
  const _component_o_button = _resolveComponent("o-button")!
  const _component_o_dropdown_item = _resolveComponent("o-dropdown-item")!
  const _component_o_dropdown = _resolveComponent("o-dropdown")!

  return (_openBlock(), _createBlock(_component_o_dropdown, _mergeProps({
    "max-height": 300,
    scrollable: _ctx.isScrollable
  }, _ctx.$attrs), {
    trigger: _withCtx(() => [
      _createVNode(_component_o_button, _normalizeProps(_guardReactiveProps(_ctx.$attrs)), {
        default: _withCtx(() => [
          (_ctx.newModelValue)
            ? (_openBlock(), _createBlock(_component_o_icon, {
                key: 0,
                style: _normalizeStyle({ color: _ctx.newModelValue }),
                class: "pt-1",
                icon: "circle",
                size: "is-small"
              }, null, 8, ["style"]))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createTextVNode(" Choose a color ")
              ], 64))
        ]),
        _: 1
      }, 16)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_o_dropdown_item, { clickable: false }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", {
              class: "column is-2",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onSelect(null)))
            }, [
              _createVNode(_component_o_icon, {
                icon: 'times',
                type: "is-danger"
              })
            ]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.colors, (color, i) => {
              return (_openBlock(), _createElementBlock("div", {
                key: i,
                class: _normalizeClass([{ 'is-selected': color === _ctx.newModelValue }, "column is-2"]),
                onClick: ($event: any) => (_ctx.onSelect(color))
              }, [
                _createVNode(_component_o_icon, {
                  style: _normalizeStyle({ color: color }),
                  icon: "circle"
                }, null, 8, ["style"])
              ], 10, _hoisted_2))
            }), 128))
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 16, ["scrollable"]))
}