

import { debounce } from 'ts-debounce';

import { defineComponent, PropType } from 'vue';

import Base from '@/mixins/Base.vue';
import { IFormsReportTypesResponse, IFormsReportType } from '@/interfaces/formsReportType';

export default defineComponent({
    mixins: [Base],
    props: {
        modelValue: {
            type: undefined as any | PropType<unknown> | PropType<Array<any>>,
            default: undefined,
        },
        platformId: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            name: '',
            page: 1,
            totalPages: 1,
            newModelValue: undefined,
            data: [] as Array<IFormsReportType>,
        };
    },
    mounted() {
        this.newModelValue = this.modelValue ? this.modelValue.code : '';

        if (!this.modelValue) this.$emit('update:modelValue', null);
    },
    methods: {
        reset(): void {
            this.page = 1;
            this.totalPages = 1;
            this.name = '';
            this.data = [];
            this.fetchData();
        },
        onFocus(): void {
            this.reset();
        },
        onSelect(obj: IFormsReportType): void {
            if (!obj) this.reset();
            this.$emit('update:modelValue', obj || null);
        },
        // eslint-disable-next-line func-names
        onTyping: debounce(function (this: any, name: string = ''): void {
            this.fetchData(name);
        }, 500),

        fetchData(name: string = '') {
            if (this.name !== name) {
                this.name = name;
                this.page = 1;
                this.totalPages = 1;
                this.data = [];
            }

            if (this.page > this.totalPages) {
                return;
            }

            this.get<IFormsReportTypesResponse>('forms-report-types', {
                params: {
                    filter: this.name,
                    page: this.page,
                    per_page: 20,
                    platform_id: this.platformId,
                },
            })
                .then(({ data }) => {
                    this.data.push(...data.data);
                    this.page += 1;

                    this.totalPages = data.meta.last_page;
                });
        },
        // eslint-disable-next-line func-names
        fetchMoreData: debounce(function (this: any, name: string): void {
            this.fetchData(name);
        }, 300),

    },
    watch: {
        modelValue(value): void {
            this.newModelValue = value ? value.code : '';
        },
    },
});
