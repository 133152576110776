import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mr-2" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(), _createBlock(_resolveDynamicComponent(!_ctx.multiple ? 'o-autocomplete' : 'o-taginput'), {
      ref: "platforms",
      modelValue: _ctx.newModelValue,
      "onUpdate:modelValue": [
        _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newModelValue) = $event)),
        _ctx.onInput
      ],
      autocomplete: _ctx.multiple ? 'autocomplete' : null,
      "before-adding": _ctx.onBeforeAdding,
      "check-infinite-scroll": true,
      clearable: true,
      data: _ctx.data,
      field: 'name',
      icon: 'cog',
      "icon-right": 'caret-down',
      loading: _ctx.loading,
      "open-on-focus": true,
      "append-to-body": "",
      placeholder: "Platform",
      onFocus: _ctx.onFocus,
      onSelect: _ctx.onSelect,
      onTyping: _ctx.onTyping,
      onInfiniteScroll: _ctx.fetchMoreData
    }, {
      default: _withCtx((props) => [
        _createElementVNode("span", _hoisted_1, _toDisplayString(props.option.name), 1)
      ]),
      empty: _withCtx(() => [
        (_ctx.loading)
          ? (_openBlock(), _createElementBlock("p", _hoisted_2, " Loading... "))
          : (_openBlock(), _createElementBlock("p", _hoisted_3, " No matching results "))
      ]),
      _: 1
    }, 40, ["modelValue", "autocomplete", "before-adding", "data", "loading", "onFocus", "onSelect", "onTyping", "onInfiniteScroll", "onUpdate:modelValue"]))
  ]))
}