

import { defineComponent, PropType } from 'vue';

export default defineComponent({
    props: {
        modelValue: {
            type: undefined as any | PropType<unknown> | PropType<Array<any>>,
            default: undefined,
        },
    },
    data() {
        return {
            isScrollable: true,
            newModelValue: this.modelValue ? this.modelValue : null,
            colors: [
                'rgb(231,106,94)',
                'rgb(85,142,241)',
                'rgb(236,166,93)',
                'rgb(170,117,207)',
                'rgb(125,204,113)',
            ],
        };
    },
    mounted() {
        this.newModelValue = this.modelValue || null;
    },
    methods: {
        onSelect(obj: any): void {
            this.newModelValue = obj;
            this.$emit('update:modelValue', obj);
        },
    },
});
