import BuildingTypes from '@/components/BuildingTypes.vue';
import Clients from '@/components/Clients.vue';
import Colors from '@/components/Colors.vue';
import Countries from '@/components/Countries.vue';
import Icons from '@/components/Icons.vue';
import Languages from '@/components/Languages.vue';
import Platforms from '@/components/Platforms.vue';
import Products from '@/components/Products.vue';
import Regions from '@/components/Regions.vue';
import Tags from '@/components/Tags.vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Oruga from '@oruga-ui/oruga-next';
import { bulmaConfig } from '@oruga-ui/theme-bulma';
import AllRules from '@vee-validate/rules';
import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import moment from 'moment';
import { defineRule, Field, Form } from 'vee-validate';
import { Component, createApp } from 'vue';
import VueAxios from 'vue-axios';
import Categories from '@/components/Categories.vue';
import FormsReportTypes from '@/components/FormsReportTypes.vue';
import str from './store/main';

import { loadLocaleMessages, setupI18n } from './i18n';
import stl from './styles.sass';

export const store = str;
export const styles = stl;

export const http = axios;

export const create = (component: Component) => {
    const app = createApp(component);

    const i18n = setupI18n();

    loadLocaleMessages(i18n, 'en');

    Object.keys(AllRules)
        .forEach((rule) => {
            defineRule(rule, AllRules[rule]);
        });

    app.config.globalProperties.$store = store;
    app.config.globalProperties.$moment = moment;

    axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT;
    axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

    axios.interceptors.request.use(
        (c: InternalAxiosRequestConfig) => {
            const config: InternalAxiosRequestConfig = c;
            if (config.method === 'get' && config.params) {
                window.Object.keys(config.params)
                    .forEach((param: any) => {
                        if (typeof config.params[param] === 'boolean') {
                            config.params[param] = config.params[param] ? 1 : 0;
                        }
                    });
            }

            if (
                config.method === 'get'
                && config.params
                && config.params.sortBy
                && config.params.orderBy
            ) {
                config.params.sort = `${config.params.sortBy}:${config.params.orderBy}`;
                config.params.per_page = config.params.per_page || 20;
                delete config.params.sortBy;
                delete config.params.orderBy;
            }
            return config;
        },
        (err) => {
            Promise.reject(err);
        },
    );

    axios.interceptors.response.use(
        (response: AxiosResponse) => {
            if (response.status >= 400) {
                return Promise.reject(response);
            }
            return Promise.resolve(response);
        },
        (error: AxiosError) => Promise.reject(error),
    );

    const MAX_REQUESTS_COUNT = 3;
    const INTERVAL_MS = 10;
    let PENDING_REQUESTS = 0;

    axios.interceptors.request.use(
        (config) => new Promise((resolve, reject) => {
            const interval = setInterval(() => {
                if (PENDING_REQUESTS < MAX_REQUESTS_COUNT) {
                    PENDING_REQUESTS += 1;
                    clearInterval(interval);
                    resolve(config);
                }
            }, INTERVAL_MS);
        }),
    );

    axios.interceptors.response.use(
        (response) => {
            PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1);
            return Promise.resolve(response);
        },
        (error) => {
            PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1);
            return Promise.reject(error);
        },
    );

    app.config.globalProperties.$filters = {
        truncate(value: string, length: number, ellipsis: string = '...') {
            if (value && value.length > length) return value.substring(0, length) + ellipsis;
            return value;
        },
    };

    app.use(VueAxios, axios);

    app.use(i18n);

    app.use(store);

    app.use(Oruga, {
        ...bulmaConfig,
        iconPack: 'fas',
        iconComponent: 'vue-fontawesome',
        modal: {
            scroll: 'clip',
            override: false,
            rootClass: (_: string, { props }: any) => {
                const classes = ['modal'];
                if (props.active || props.programmatic) classes.push('is-active');
                return classes.join(' ');
            },
            overlayClass: 'modal-background',
            contentClass: 'animation-content',
            closeClass: 'modal-close is-large',
            fullScreenClass: 'is-full-screen',
        },
    });

    library.add(fas);

    app.component('VueFontawesome', FontAwesomeIcon);
    app.component('languages', Languages);
    app.component('products', Products);
    app.component('forms-report-types', FormsReportTypes);
    app.component('clients', Clients);
    app.component('building-types', BuildingTypes);
    app.component('colors', Colors);
    app.component('platforms', Platforms);
    app.component('countries', Countries);
    app.component('categories', Categories);
    app.component('regions', Regions);
    app.component('tags', Tags);
    app.component('icons', Icons);

    app.component('vee-field', Field);
    app.component('vee-form', Form);

    return app;
};
