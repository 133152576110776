import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_o_autocomplete = _resolveComponent("o-autocomplete")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_o_autocomplete, {
      modelValue: _ctx.newModelValue,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newModelValue) = $event)),
      "check-infinite-scroll": true,
      clearable: true,
      data: _ctx.data,
      field: 'name',
      icon: 'tag',
      "icon-right": 'caret-down',
      loading: _ctx.loading,
      "open-on-focus": true,
      "append-to-body": "",
      placeholder: "Category",
      onFocus: _ctx.onFocus,
      onSelect: _ctx.onSelect,
      onTyping: _ctx.onTyping,
      onInfiniteScroll: _ctx.fetchMoreData
    }, {
      empty: _withCtx(() => [
        (_ctx.loading)
          ? (_openBlock(), _createElementBlock("p", _hoisted_1, " Loading... "))
          : (_openBlock(), _createElementBlock("p", _hoisted_2, " No matching results "))
      ]),
      _: 1
    }, 8, ["modelValue", "data", "loading", "onFocus", "onSelect", "onTyping", "onInfiniteScroll"])
  ]))
}