
import Base from '@/mixins/Base.vue';
import { defineComponent } from 'vue';
import sidebar from './components/Sidebar.vue';

export default defineComponent({
    mixins: [Base],
    components: {
        sidebar,
    },
    mounted(): void {
        this.$router.afterEach(() => {
            this.isSidebarCollapsed = false;
        });
    },
    data(): any {
        return {
            isSidebarCollapsed: false,
        };
    },
    methods: {
        logout(): void {
            window.location.href = `${process.env.VUE_APP_KEYCLOAK_BASE_URL}/realms/${process.env.VUE_APP_KEYCLOAK_REALM}/protocol/openid-connect/logout`;
        },
    },
});
