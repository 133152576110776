
import Base from '@/mixins/Base.vue';
import { defineComponent } from 'vue';

import { IUser } from '../interfaces/user';

export default defineComponent({
    mixins: [Base],
    computed: {
        user(): IUser {
            return this.$store.getters.user;
        },
    },
});
