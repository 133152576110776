import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "loading-bar" }
const _hoisted_2 = { class: "main-page" }
const _hoisted_3 = { class: "main-page-content" }
const _hoisted_4 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sidebar = _resolveComponent("sidebar")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{ 'is-collapsed': _ctx.isSidebarCollapsed }, "main-layer"])
  }, [
    _createVNode(_component_sidebar, {
      onLogout: _cache[0] || (_cache[0] = ($event: any) => (_ctx.logout())),
      onToggle: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isToggled = !_ctx.isToggled)),
      onToggleSidebar: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isSidebarCollapsed = !_ctx.isSidebarCollapsed)),
      onCloseSidebar: _cache[3] || (_cache[3] = ($event: any) => (_ctx.isSidebarCollapsed = false))
    }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: _normalizeClass([{ 'is-active': _ctx.loading }, "loading-bar-inner"])
      }, null, 2)
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_router_view)
        ])
      ])
    ])
  ], 2))
}