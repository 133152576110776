<template>
    <o-select
        v-model="$store.state.currentLanguage">
        <option
            v-for="(lang, l) in $store.state.locales"
            :key="l"
            :value="lang.code"
        >
            {{
                lang.name
            }}
        </option>
    </o-select>
</template>
