import { nextTick } from 'vue';
import { createI18n } from 'vue-i18n';

export const SUPPORT_LOCALES = ['en', 'ja'];

// @ts-ignore
export function setI18nLanguage(i18n: any, locale: string): void {
    const param = i18n;
    const currentLocale = locale;

    if (param.mode === 'legacy') {
        param.global.locale = currentLocale;
    } else {
        param.global.locale.value = currentLocale;
    }
}

export function setupI18n(options = { locale: 'en' }) {
    const i18n = createI18n(options);
    setI18nLanguage(i18n, options.locale);
    return i18n;
}

export function loadLocaleMessages(i18n: any, locale: string) {
    // load locale messages with dynamic import
    const messages = require(`./locales/${locale}.json`).default;

    i18n.global.setLocaleMessage(locale, messages);

    return nextTick();
}
