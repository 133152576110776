

import { debounce } from 'ts-debounce';

import { IPlatform, IPlatformsResponse } from '@/interfaces/platform';

import Base from '@/mixins/Base.vue';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
    mixins: [Base],
    props: {
        multiple: {
            type: Boolean,
            default: false,
        },
        modelValue: {
            type: undefined as any | PropType<unknown> | PropType<Array<any>>,
            default: undefined,
        },
        clientId: {
            type: Number,
            default: null,
        },
    },
    data() {
        return {
            name: '',
            page: 1,
            totalPages: 1,
            newModelValue: undefined,
            data: [] as Array<IPlatform>,
        };
    },
    mounted() {
        if (!this.multiple) this.newModelValue = this.modelValue ? (this.modelValue as any).name : '';
        else this.newModelValue = this.modelValue ? this.modelValue : [];

        if (!this.modelValue) this.$emit('update:modelValue', this.multiple ? [] : null);
    },
    methods: {
        reset(): void {
            this.page = 1;
            this.totalPages = 1;
            this.name = '';
            this.data = [];
            this.fetchData();
        },
        onBeforeAdding(value: any): boolean {
            if (this.multiple && this.newModelValue && (this.newModelValue as any).findIndex((v: any) => v.id === value.id) !== -1) {
                return false;
            }
            return true;
        },
        onFocus(): void {
            this.reset();
        },
        onSelect(obj: IPlatform): void {
            if (!obj) this.reset();
            if (!this.multiple) this.$emit('update:modelValue', obj || null);
        },
        onInput(obj: IPlatform): void {
            if (this.multiple) this.$emit('update:modelValue', obj || []);
        },
        // eslint-disable-next-line func-names
        onTyping: debounce(function (this: any, name: string = ''): void {
            this.fetchData(name);
        }, 500),

        fetchData(name: string = '') {
            if (this.name !== name) {
                this.name = name;
                this.page = 1;
                this.totalPages = 1;
                this.data = [];
            }

            if (this.page > this.totalPages) {
                return;
            }

            this.get<IPlatformsResponse>('platforms', {
                params: {
                    filter: this.name,
                    page: this.page,
                    client_id: this.clientId,
                },
            })
                .then(({ data }) => {
                    this.data.push(...data.data);
                    this.page += 1;

                    this.totalPages = data.meta.last_page;
                });
        },
        // eslint-disable-next-line func-names
        fetchMoreData: debounce(function (this: any, name: string): void {
            this.fetchData(name);
        }, 300),

    },
    watch: {
        modelValue(value): void {
            if (this.multiple) this.newModelValue = value || [];
            else this.newModelValue = value ? value.name : '';
        },
        clientId(): void {
            this.reset();
        },
    },
});
