import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'home',
        component: () => import('../pages/Home.vue'),
    },
    {
        path: '/projects',
        name: 'projects.list',
        component: () => import('../pages/projects/List.vue'),
        children: [
            {
                path: 'edit/:id',
                name: 'projects.edit',
                component: () => import('../pages/projects/Edit.vue'),
            },
            {
                path: 'delete/:id',
                name: 'projects.delete',
                component: () => import('../pages/projects/Delete.vue'),
            },
            {
                path: 'create',
                name: 'projects.create',
                component: () => import('../pages/projects/Create.vue'),
            },
        ],
    },
    {
        path: '/projects/:project_id/steps',
        name: 'projects.steps.list',
        component: () => import('../pages/projects/steps/List.vue'),
        children: [
            {
                path: 'create',
                name: 'projects.steps.create',
                component: () => import('../pages/projects/steps/Create.vue'),
            },
            {
                path: 'edit/:id',
                name: 'projects.steps.edit',
                component: () => import('../pages/projects/steps/Edit.vue'),
            },
            {
                path: 'delete/:id',
                name: 'projects.steps.delete',
                component: () => import('../pages/projects/steps/Delete.vue'),
            },
        ],
    },
    {
        path: '/clients',
        name: 'clients.list',
        component: () => import('../pages/clients/List.vue'),
        children: [
            {
                path: 'edit/:id',
                name: 'clients.edit',
                component: () => import('../pages/clients/Edit.vue'),
            },
            {
                path: 'delete/:id',
                name: 'clients.delete',
                component: () => import('../pages/clients/Delete.vue'),
            },
            {
                path: 'create',
                name: 'clients.create',
                component: () => import('../pages/clients/Create.vue'),
            },
        ],
    },
    {
        path: '/infringements',
        name: 'infringements.list',
        component: () => import('../pages/infringements/List.vue'),
        children: [
            {
                path: 'edit/:id',
                name: 'infringements.edit',
                component: () => import('../pages/infringements/Edit.vue'),
            },
            {
                path: 'delete/:id',
                name: 'infringements.delete',
                component: () => import('../pages/infringements/Delete.vue'),
            },
            {
                path: 'create',
                name: 'infringements.create',
                component: () => import('../pages/infringements/Create.vue'),
            },
        ],
    },
    {
        path: '/categories',
        name: 'categories.list',
        component: () => import('../pages/categories/List.vue'),
        children: [
            {
                path: 'edit/:id',
                name: 'categories.edit',
                component: () => import('../pages/categories/Edit.vue'),
            },
            {
                path: 'delete/:id',
                name: 'categories.delete',
                component: () => import('../pages/categories/Delete.vue'),
            },
            {
                path: 'create',
                name: 'categories.create',
                component: () => import('../pages/categories/Create.vue'),
            },
        ],
    },
    {
        path: '/products',
        name: 'products.list',
        component: () => import('../pages/products/List.vue'),
        children: [
            {
                path: 'edit/:id',
                name: 'products.edit',
                component: () => import('../pages/products/Edit.vue'),
            },
            {
                path: 'delete/:id',
                name: 'products.delete',
                component: () => import('../pages/products/Delete.vue'),
            },
            {
                path: 'create',
                name: 'products.create',
                component: () => import('../pages/products/Create.vue'),
            },
        ],
    },
    {
        path: '/platforms',
        name: 'platforms.list',
        component: () => import('../pages/platforms/List.vue'),
        children: [
            {
                path: 'edit/:id',
                name: 'platforms.edit',
                component: () => import('../pages/platforms/Edit.vue'),
            },
            {
                path: 'delete/:id',
                name: 'platforms.delete',
                component: () => import('../pages/platforms/Delete.vue'),
            },
            {
                path: 'create',
                name: 'platforms.create',
                component: () => import('../pages/platforms/Create.vue'),
            },
        ],
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
